import { WorkerSearch } from "../../../../core/interfaces/worker-search.interface";

export class LoadGroupMessage {
	public static readonly type = "[Group Message] load";
	constructor(
		public skipCount: number,
		public maxResult: number
	) {}
}

export class GetAllWorkers {
	static readonly type = "[Group Message] get workers";
	constructor(
    public workerSearch: WorkerSearch
  ) {}
}