import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { GroupMessageService } from "../../services/group-message.service";
import { RosterService } from "../../services/roster.service";
import { GetAllWorkers, LoadGroupMessage } from "./group-message.actions";
import { GroupMessageModel } from "./group-message.model";

@State<GroupMessageModel>({
	name: "groupMessage",
	defaults: {
    loading: false,
    error: false,

    groupMessage: [],
		totalGroupMessage: 0,
		roster: []
	}
})
@Injectable()
export class GroupMessageState {
	constructor(
		private groupMessageService: GroupMessageService,
    private rosterService: RosterService
	) {}

	@Selector() static loading(state: GroupMessageModel) { return state.loading; }
	@Selector() static error(state: GroupMessageModel) { return state.error; }

	@Selector() static groupsMessage(state: GroupMessageModel) { return state.groupMessage; }
	@Selector() static totalGorupsMessage(state: GroupMessageModel) { return state.totalGroupMessage; }
	@Selector() static roster(state: GroupMessageModel) { return state.roster; }

  @Action(LoadGroupMessage)
	loadGroupMessage(ctx: StateContext<GroupMessageModel>, { skipCount, maxResult }) {
		ctx.patchState({
      groupMessage: [],
      loading: true,
      error: false,
      totalGroupMessage: null
    });

		return this.groupMessageService.getGroupsMessage(skipCount, maxResult).pipe(
      tap(
        (response) => {
          ctx.patchState({
            groupMessage: response.items,
            loading: false,
            totalGroupMessage: response.totalCount
          });
        },
        (error) => {
          ctx.patchState({ 
            loading: false,
            error: true
          });
        }
      )
    );
	}

  @Action(GetAllWorkers)
	getAllWorkers(ctx: StateContext<GroupMessageModel>, { workerSearch }) {
		ctx.patchState({
      roster: [],
      loading: true,
      error: false
    });

		return this.rosterService.getWorker(
			workerSearch.value,
			workerSearch.unionId,
			workerSearch.skipCount,
			workerSearch.maxCount,
			workerSearch.date,
			workerSearch.availabilityTypeIds,
      null,
			workerSearch.addAssigned,
			true,
      null
		).pipe(
			tap(
        (response) => {
          ctx.patchState({
            roster: response.items,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({
            loading: false,
            error: true
          });
        }
      )
		);
	}

}