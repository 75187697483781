import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";

@Injectable({ providedIn: 'root' })
export class GroupMessageService {

  private apiUrl: string;

  constructor(
		private http: HttpClient,
		private env: EnvironmentService
	) {
		this.apiUrl = this.env.getValueTransferState(API_URL);
	}
  
  createWorkerGroupMessage(workerIds: number[], name: string, unionId: number) {
		return this.http.post<any>(`${this.apiUrl}/app/workerGroup`, { name, workerIds, unionId });
	}

	updateWorkerGroupMessage(groupId: string, workerIds: number[], name: string, unionId: number) {
		return this.http.put<any>(`${this.apiUrl}/app/workerGroup/${groupId}`, { name, workerIds, unionId });
	}

	removeWorkerGroupMessage(workerGroupId: string) {
		return this.http.delete<any>(`${this.apiUrl}/app/workerGroup/${workerGroupId}`);
	}

	getGroupsMessage(skipCount: number, maxResult: number) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        MaxResultCount: maxResult,
        SkipCount: skipCount
      }
    });
		return this.http.get<any>(`${this.apiUrl}/app/workerGroup`, { params });
	}
  
	getWorkerGroupById(id: string) {
		return this.http.get<any>(`${this.apiUrl}/app/workerGroup/${id}/withWorkers`);
	}

}